<template>
  <div v-if="!searching">
    <div class="header">
      <div class="d-flex">
        <a-avatar class="me-1 avatar-position" size="large">G</a-avatar>
        <h5>{{ t("athletes.guard") }}</h5>
      </div>
      <!-- <a-button
        type="link"
        size="large"
        :onClick="() => onChangeVisibleModel('GK')"
      >
        <sat-icon
          name="filterIcon"
          style="
             {
              width: 32px;
            }
          "
        />
      </a-button> -->
      <team-filter @onSubmit="handleFilter" type="G" />
    </div>
    <!-- loadingGuard -->
    <div
      v-if="loadingGuard"
      class="loading-container"
      style="height: 200px"
    >
      <LoadingOutlined />
    </div>
  <!--  <sat-card-carousel
      v-else-if="guard.length > 0"
      type="team"
      :max="5"
      filter="defender"
      :teamsId="guard"
      :id="teamId"
      class="mb-5"
      :onFetchData="onFetchGuard"
      :queryFilter="queryFilter"
      :onClickCard="onClickCard"
    />
    <a-empty v-else />
    <div class="header">
      <div class="d-flex">
        <a-avatar class="me-1 avatar-position" size="large">DF</a-avatar>
        <h5>{{ t("athletes.defender") }}</h5>
      </div>

      <team-filter @onSubmit="handleFilter" type="DF" />
    </div>
    <div v-if="loadingDefender" class="loading-container" style="height: 200px">
      <LoadingOutlined />
    </div>
    <sat-card-carousel
      v-else-if="defender.length > 0"
      type="team"
      :max="5"
      filter="guard"
      :teamsId="defender"
      :id="teamId"
      class="mb-5"
      :onFetchData="onFetchDefender"
      :queryFilter="queryFilter"
      :onClickCard="onClickCard"
    />-->
    <a-empty v-else />
    <div class="header">
      <div class="d-flex">
        <a-avatar class="me-1 avatar-position" size="large">C</a-avatar>
        <h5>{{ t("athletes.center") }}</h5>
      </div>
      <!-- <a-button
        type="link"
        size="large"
        @click="() => onChangeVisibleModel('MF')"
      >
        <sat-icon
          name="filterIcon"
          style="
             {
              width: 32px;
            }
          "
        />
      </a-button> -->
      <team-filter @onSubmit="handleFilter" type="C" />
    </div>
    <div
      v-if="loadingCenter"
      class="loading-container"
      style="height: 200px"
    >
      <LoadingOutlined />
    </div>
    <sat-card-carousel
      v-else-if="center.length > 0"
      type="team"
      :max="5"
      filter="center"
      :teamsId="center"
      :id="teamId"
      class="mb-5"
      :onFetchData="onFetchgetCenter"
      :queryFilter="queryFilter"
      :onClickCard="onClickCard"
    />
    <a-empty v-else />
    <div class="header">
      <div class="d-flex">
        <a-avatar class="me-1 avatar-position" size="large">F</a-avatar>
        <h5>{{ t("athletes.forward") }}</h5>
      </div>
      <!-- <a-button
        type="link"
        size="large"
        @click="() => onChangeVisibleModel('FW')"
      >
        <sat-icon
          name="filterIcon"
          style="
             {
              width: 32px;
            }
          "
        />
      </a-button> -->
      <team-filter @onSubmit="handleFilter" type="F" />
    </div>
    <div v-if="loadingForward" class="loading-container" style="height: 200px">
      <LoadingOutlined />
    </div>
    <sat-card-carousel
      v-else-if="forward.length > 0"
      type="team"
      :max="5"
      filter="forward"
      :teamsId="forward"
      :id="teamId"
      class="mb-5"
      :onFetchData="onFetchgetForward"
      :queryFilter="queryFilter"
      :onClickCard="onClickCard"
    />
    <a-empty v-else />
  </div>
  <a-skeleton v-else-if="loadingSearch" active />
  <div v-else-if="resultSearch.length > 0">
    <a-row :gutter="[25, 20]">
          <a-col v-for="data in resultSearch" :key="data.id">

      <!-- <a-col v-for="data in searchResult" :key="data.id"> -->
        <sat-card :data="data" type="team" style="width: 228px" />
      </a-col>
    </a-row>
  </div>
  <a-empty v-else />
</template>

<script >
import SatCard from "@/components/carousel/SatCard.vue";
import { defineComponent, onMounted, onUpdated, reactive, toRefs ,watch} from "vue";
import SatIcon from "@/components/SatIcon.vue";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import NationalitySelector from "@/components/selector/NationalitySelector.vue";
import PositionSelector from "@/components/selector/PositionSelector.vue";
import DivisionSelector from "@/components/selector/DivisionSelector.vue";
import { useRequest } from "vue-request";
import { teamService } from "@/_services";
import { useRoute, useRouter } from "vue-router";
import TeamFilter from "@/components/SatTeamFilter.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const status = [
  { label: "ทั้งหมด", value: "null", isActive: true },
  { label: "ประจำ", value: "false", isActive: false },
  { label: "ยืมตัว", value: "true", isActive: false },
];

export default defineComponent({
  name: "slideTeamMember",
  components: {
    SatIcon,
    LoadingOutlined,
    SatCardCarousel,
    DeleteOutlined,
    NationalitySelector,
    PositionSelector,
    DivisionSelector,
    SatCard,
    TeamFilter,
  },
  props: {
    teamId: Number,
    guard: Array,
    // defender: Array,
    center: Array,
    forward: Array,
    onFetchData: Object,
    searching: Boolean,
    resultSearch: Array,
    loadingSearch: Boolean,
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const { resultSearch, onFetchData, teamId } = props;

    const state = reactive({
      loadingGuard: true,
    //   loadingDefender: true,
      loadingCenter: true,
      loadingForward: true,
      guard: [],
    //   defender: [],
      center: [],
      forward: [],
      result: [],
      queryFilter: {},
      searchResult: [],
      visibleModal: false,
      ageRange: [15, 35],
      nationalityStatus: null,
      positionStatus: 0,
      departmentStatus: 0,
      contractStatus: [...status],
      selectedTags: [...status],
      contract: "null",
      typeModel: null,
    });


    const { run: getPlayerCurrentGuard } = useRequest(
      teamService.getPlayerCurrentGuard,
      {
        manual: true,
      }
    );
    // const { run: getPlayerCurrentDefender } = useRequest(
    //   teamService.getPlayerCurrentDefender,
    //   {
    //     manual: true,
    //   }
    // );
    const { run: getPlayerCurrentCenter } = useRequest(
      teamService.getPlayerCurrentCenter,
      {
        manual: true,
      }
    );
    const { run: getPlayerCurrentForward } = useRequest(
      teamService.getPlayerCurrentForward,
      {
        manual: true,
      }
    );

    const formatResult = (data) => {
      const result = data.map((da) => {
        return {
          id: da?.accounts?.account_id,
          name: {
            en:
              `${da?.accounts?.account_informations?.account_information_firstname_en} ${da?.accounts?.account_informations?.account_information_lastname_en}` ??
              "",
            th:
              `${da?.accounts?.account_informations?.account_information_firstname_th} ${da?.accounts?.account_informations?.account_information_lastname_th}` ??
              "",
          },
          avatar: da?.accounts?.account_informations?.account_media_preview_url,
          age:
            da?.accounts?.account_informations?.account_information_age ?? "-",
          nationallity: {
            th:
              da?.accounts?.account_informations
                .account_information_nationality ?? "-",
            en:
              da?.accounts?.account_informations
                .account_information_nationality_en ?? "-",
          },
        };
      });
      return result;
    };

    const onFetchGuard = async (params) => {
      const { isFilter, isNext, ...other } = params;
      if (!isNext) {
        state.loadingGuard = true;
      }
      const { data } = await getPlayerCurrentGuard(teamId, other);
      if (isFilter) {
        state.guard = data.team_player_history.data;
      } else {
        state.guard = [
          ...state.guard,
          ...data.team_player_history.data,
        ];
      }
      state.guard = formatResult(state.guard);

      state.loadingGuard = false;
      return data;
    };

    // const onFetchDefender = async (params) => {
    //   const { isFilter, isNext, ...other } = params;
    //   if (!isNext) {
    //     state.loadingDefender = true;
    //   }
    //   const { data } = await getPlayerCurrentDefender(teamId, other);
    //   // console.log("onFetchDefender", data);
    //   if (isFilter) {
    //     state.defender = data.team_player_history.data;
    //   } else {
    //     state.defender = [...state.defender, ...data.team_player_history.data];
    //   }

    //   state.defender = formatResult(state.defender);
    //   // console.log("defender", state.defender);
    //   state.loadingDefender = false;
    //   return data;
    // };

    const onFetchgetCenter = async (params) => {
      const { isFilter, isNext, ...other } = params;
      if (!isNext) {
        state.loadingCenter = true;
      }
      const { data } = await getPlayerCurrentCenter(teamId, other);
      if (isFilter) {
        state.center = data.team_player_history.data;
      } else {
        state.center = [
          ...state.center,
          ...data.team_player_history.data,
        ];
      }
      state.center = formatResult(state.center);

      state.loadingCenter = false;
      return data;
    };

    const onFetchgetForward = async (params) => {
      const { isFilter, isNext, ...other } = params;
      if (!isNext) {
        state.loadingForward = true;
      }
      const { data } = await getPlayerCurrentForward(teamId, other);
      if (isFilter) {
        state.forward = data.team_player_history.data;
      } else {
        state.forward = [...state.forward, ...data.team_player_history.data];
      }
      state.forward = formatResult(state.forward);

      state.loadingForward = false;
      return data;
    };

    onMounted(() => {
      try {
        // console.log('resultSearch',resultSearch);
        // state.searchResult = formatResult(resultSearch)
        onFetchGuard({ page: 1, size: 5 });
        // onFetchDefender({ page: 1, size: 5 });
        onFetchgetCenter({ page: 1, size: 5 });
        onFetchgetForward({ page: 1, size: 5 });
      } catch (error) {
        // console.log("onMounted :: catch", error);
      } finally {
        // console.log("onMounted :: finally", state);
      }
    });

    const onChangeVisibleModel = (type) => {
      state.typeModel = type;
      state.visibleModal = !state.visibleModal;
    };

    const reset = () => {
      state.nationalityStatus = null;
      state.contractStatus = [
        { label: "ทั้งหมด", value: "null", isActive: true },
        { label: "ประจำ", value: "false", isActive: false },
        { label: "ยืมตัว", value: "true", isActive: false },
      ];
      state.contract = "null";
      state.ageRange = [15, 35];
    };

    const handleNationalChange = (selected) => {
      state.nationalityStatus = selected;
    };

    const handlePositionChange = (selected) => {
      state.positionStatus = +selected;
    };

    const handleDivisionChange = (selected) => {
      state.departmentStatus = +selected;
    };

    const handleTagChange = (tag, checked) => {
      const { selectedTags } = state;

      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t.value !== tag.value);
      state.selectedTags = nextSelectedTags;
    };

    const onClickContract = (value) => {
      state.contractStatus = state.contractStatus.map((button) => {
        if (button.value === value) {
          button.isActive = true;
        } else {
          button.isActive = false;
        }
        return button;
      });
      state.contract = value;
    };

    const handleFilter = (params) => {
      const { type, ...other } = params;
      switch (type) {
        case "G":
          onFetchGuard({ ...other });
          break;
        // case "DF":
        //   onFetchDefender({ ...other });
        //   break;
        case "C":
          onFetchgetCenter({ ...other });
          break;
        case "F":
          onFetchgetForward({ ...other });
          break;
        default:
          break;
      }
      // state.visibleModal = false;
    };

    const onClickCard = (data) => {
      router.push(`/athletes/${data.accounts.account_id}`);
    };
    return {
      t,
      store,
      ...toRefs(state),
      onChangeVisibleModel,
      handleNationalChange,
      handlePositionChange,
      handleDivisionChange,
      handleTagChange,
      // handleChangeContractStatus,
      onClickContract,
      handleFilter,
      reset,
      onFetchGuard,
    //   onFetchDefender,
      onFetchgetCenter,
      onFetchgetForward,
      onClickCard,
    };
  },
});
</script>

<style lang="less" scoped>
h5 {
  margin: auto 0px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.avatar-position {
  background-color: #0645ad;
}
</style>